.pageContainer {
    background-color: #9B1F35;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    color: #FFFFFF;
    align-items: center; 
  }
  
  .headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: center; 
    align-items: center; 
    margin-top: 50px;
  }

  .bodyContainer {
    align-items: center; 
    text-align: center; 
    margin-top: 40px;
    padding: 10px;
    text-align: center;
  }

  .decorativeTextRight {
    color: #ffffff33;
    font-family: 'Lekton', sans-serif;
    font-size: 35px;
    align-self: flex-end;
    margin-top: 40px;
  }

  .decorativeTextLeft {
    color: rgba(255, 255, 255, 0.2);
    font-family: 'Lekton', sans-serif;
    font-size: 35px;
    align-self: flex-start;
    margin-top: auto;
  }

.stanfordLogo {
    font-family: 'Source Serif 4', serif;
    font-size: 75px;
}

.line {
    border-left: 2px solid #FFFFFF;
    border-bottom: 0px;
    height: 75px;
    margin-right: 20px;
    margin-left: 20px;
}

.classConnectLogo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;

}

.emailInput {
  background-color: #ffffff80;
  border: none; 
  border-radius: 20px;
  width: 250px;
  height: 10px;
  padding: 15px;
  margin-top: 5px;
  margin-bottom: 20px;
}

input:focus {
  outline: none;
  border: none; 
  background-color: #FFFFFF;
}

.h4:hover {
  border-bottom: 2px solid #FFFFFF;
  padding-bottom: 2px;
}

@media (max-width: 637px) {
  .line {
    border-left: 0px;
    border-bottom: 2px solid #FFFFFF;
    height: 0px;
    width: 300px;
  }
  .headerContainer {
    flex-direction: column;
  }
}