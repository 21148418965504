html {
    height: 100%;
  }
  
  body {
    background-color: #9B1F35;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
  }

  .h1 {
    font-family: 'Lekton', sans-serif;
    font-size: 35px;
    font-weight: 700;
  }

  .h2 {
    font-family: 'Lekton', sans-serif;
    font-size: 25px;
  }

  .h3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 300;
  }

  .h4 {
    font-family: 'Lekton', sans-serif;
    font-size: 20px;
  }